import { webatlasTileLayer, WebatlasTileLayerTypes } from 'leaflet-webatlastile';
import L from 'leaflet';

function addMarker(map, latitude, longitude, content) {

    const markerIcon = L.icon({
        iconSize: [25, 41],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        // specify the path here
        iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
        shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
    });

    var marker = L.marker([latitude, longitude], { icon: markerIcon });
    marker.addTo(map);

    var popup = L.popup().setContent(content);
    marker.bindPopup(popup).openPopup();
}

function initializeMap(container, latitude, longitude, zoom) {
    // Create a map
    var map = L.map(
        container,
        { dragging: !L.Browser.mobile, tap: !L.Browser.mobile, fullscreenControl: true }
    ).setView([latitude, longitude], zoom);

    // Peanuts demo API key from Arild Nomeland
    var apiKey = '07C4A129-9D26-4B3D-9BC8-B22E4B6E509E';

    var baseLayers = {
        'Kart': webatlasTileLayer({
            mapType: WebatlasTileLayerTypes.VECTOR,
            apiKey: apiKey
        }).addTo(map),
        'Foto': webatlasTileLayer({
            mapType: WebatlasTileLayerTypes.AERIAL,
            apiKey: apiKey
        }),
        'Hybrid': webatlasTileLayer({
            mapType: WebatlasTileLayerTypes.HYBRID,
            apiKey: apiKey
        })
    };
    L.control.layers(baseLayers).addTo(map);
    return map;
}

window.initializeMap = initializeMap;
window.addMarker = addMarker;